<template>
  <div class="relative">
    <MenuToggle />
    <div id="blob" class="absolute top-0 left-0 w-1/2 -z-10">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
        <path
          d="M1891 560c0-277-224-509-501-499-372 13-397 281-504 302s-215-179-511-75a459 459 0 0 0-222 218C10 809 262 886 259 992s-201 171-82 395c34 64 99 127 212 141 56 6 103 47 115 102a377 377 0 0 0 383 310c222 2 528-202 380-536-42-97 30-123 73-194s-11-120 128-156c233-61 423-246 423-494Z">
        </path>
      </svg>
    </div>

    <div id="logo" class="logo-alignment max-w-[1200px] z-10">
      <Logo />
    </div>
    <div class="overflow-hidden">
      <NuxtPage />
      <Footer />
    </div>
  </div>
</template>

<script lang="ts" setup>
onMounted(() => {
  document.body.classList.add('home-page');
});
onBeforeUnmount(() => {
  document.body.classList.remove('home-page');
});



</script>

<style scoped>
#logo {
  @apply w-screen;
}

#blob {
  svg {
    @apply fill-stone-200 dark:fill-stone-800;

  }
}

#logo svg {
  @apply w-full;
}
</style>